<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#437ccc" right timeout="3000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pb-3 class="bold" style="font-family:pregular">
      <v-flex xs12 pb-3>
        <h4>Division Name: &nbsp;{{ $route.query.division }}</h4> 
         <!-- <h4>Range Name: &nbsp;{{ $route.query.division }}</h4> -->
      </v-flex>
       <v-flex xs12 pb-3>
        <!-- <h4>Division Name: &nbsp;{{ $route.query.division }}</h4>  -->
         <h4>Range Name: &nbsp;{{ $route.query.Rangename }}</h4>
      </v-flex>
       <v-flex xs12 pb-3>
        <!-- <h4>Division Name: &nbsp;{{ $route.query.division }}</h4>  -->
         <h4>Beat Name: &nbsp;{{ $route.query.beat }}</h4>
      </v-flex>
      <v-flex xs6>
        <h3 class="bold">COMP</h3>
      </v-flex>
      <v-flex xs6 text-right>
        <v-btn color="primary" dark dense @click="myDialog = true">
          Add comp <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="allData.length >0" style="font-family:pregular">
      <v-flex xs12 sm4 md3 pa-1 v-for="(item, i) in allData" :key="i">
        <v-card elevation="4">
          <v-layout wrap pa-3>
            <v-flex xs6>
              <h4>{{ item.compName }}</h4>
            </v-flex>
            <v-flex  xs6 text-right align-self-center pr-2>
                  <!-- <v-btn x-small outlined :to="'/beat?id=' +item._id +'&name='+item.rangeName + '&division'+ $route.query.name + '&divisonId=' + $route.query.divisionId" > view beat</v-btn> -->
            </v-flex>

            <v-flex xs12 text-right>
              <v-btn
                dark
                text
                color="error"
                x-small
                @click="(deleteDialog = true), (deleteId = item._id)"
              >
                delete
              </v-btn>
              <v-btn
                class="ml-1"
                text
                color="primary"
                x-small
                @click="
                  (editDialog = true),
                    ((Editdivision = item.compName), (editId = item._id))
                "
              >
                edit
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
     <v-layout wrap v-else style="font-family:pregular">
      <v-flex xs12 text-center>
            <h4>No Data Available......</h4>
      </v-flex>
    </v-layout>
    <v-dialog width="500px" v-model="deleteDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <h4>Are you sure you want to remove this comb ?? </h4>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn x-small text color="error" @click="deleteDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              text
              x-small
              color="primary"
              @click="deleteDivision()"
            >
              remove
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog width="500px" v-model="myDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12> </v-flex>
          <v-flex xs12>
            <v-text-field label="Comp Name" outlined dense v-model="division">
            </v-text-field>
          </v-flex>
          <v-flex xs12 text-right>
           
            <v-btn x-small color="primary" @click="addDivision()">
              Save
            </v-btn>
             <v-btn class="ml-1"  x-small color="error" @click="myDialog = false">
              close</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog width="500px" v-model="editDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <v-text-field
              label="comp Name"
              outlined
              dense
              v-model="Editdivision"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn x-small color="error" @click="editDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              x-small
              color="primary"
              @click="EditDivisions()"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      deleteDialog: false,
      deleteId: null,
      editId: null,
      editDialog: false,
      Editdivision: "",
      showsnackbar: false,
      msg: "",
      appLoading: false,
      ServerError: false,
      myDialog: false,
      division: "",

      allData: [],
      allDivisions: [],
    };
  },

  mounted() {
    this.getData();
    this.getDivisions();
  },

  methods: {
    getDivisions() {
      this.appLoading = true;
      axios({
        url: "/view/all/divisions",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allDivisions = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteDivision() {
      axios({
        url: "/delete/comp",
        method: "post",
        data: {
          id: this.deleteId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.deleteDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    EditDivisions() {
      axios({
        url: "/edit/comb",
        method: "post",
        data: {
          id: this.editId,
          name: this.Editdivision,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.editDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/view/all/comp",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          divisionId: this.$route.query.divisionId,
          rangeId: this.$route.query.rangeId,
          beatId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    addDivision() {
      axios({
        url: "/add/comp",
        method: "post",
        data: {
          divisionId: this.$route.query.divisionId,
          compName: this.division,
          rangeId : this.$route.query.rangeId,
          beatId:  this.$route.query.id

        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.myDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
